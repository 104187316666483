import { INITIAL_URL_LOCALSTORAGE_KEY } from 'src/global';

export const getRedirectUrl = () => {
  if (typeof window === 'undefined' || typeof localStorage === 'undefined') {
    return null;
  }

  const localStorageUrl = localStorage.getItem(INITIAL_URL_LOCALSTORAGE_KEY);
  return localStorageUrl ? decodeURIComponent(localStorageUrl) : '/';
};
