import { ReadonlyURLSearchParams } from 'next/navigation';
import { createContext } from 'react';
import { noop } from 'src/utils/noop';

import { EMPTY_SEARCH_PARAMS } from './constants';

type SearchParamsContextValue = {
  searchParams: ReadonlyURLSearchParams;
  setSearchParams: (searchParams: ReadonlyURLSearchParams) => void;
};

export const SearchParamsContext = createContext<SearchParamsContextValue>({
  searchParams: EMPTY_SEARCH_PARAMS,
  setSearchParams: noop,
});
