import { User } from 'oidc-client-ts';
import { INITIAL_URL_LOCALSTORAGE_KEY } from 'src/global';

import { OIDC_CONFIG } from '../config';

export function getOidcUserLSKey() {
  return `user:${OIDC_CONFIG.authority}:${OIDC_CONFIG.client_id}`;
}

export function getOidcUserFromLocalStorage() {
  if (typeof window === 'undefined' || typeof localStorage === 'undefined') {
    return null;
  }

  const oidcStorage = localStorage.getItem(`oidc.${getOidcUserLSKey()}`);
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
}

export function removeOidcUserFromLocalStorage() {
  localStorage.removeItem(`oidc.${getOidcUserLSKey()}`);
}

export function saveCurrentPathForRedirect() {
  if (window) {
    const { search = '' } = window.location;
    const params = search.includes('code') ? '' : search;

    const urlToSave = `${window.location.pathname}${params}`;

    localStorage.setItem(
      INITIAL_URL_LOCALSTORAGE_KEY,
      encodeURIComponent(urlToSave),
    );
  }
}
