import { useRouter } from 'next/navigation';
import { ReactNode, useEffect, useState } from 'react';
import { AuthProvider, useAuth } from 'react-oidc-context';
import { useSearchParams } from 'src/contexts/SearchParams/useSearchParams';
import { OIDC_AUTHORITY_URL } from 'src/global';

import { OIDC_CONFIG } from '../config';
import { getRedirectUrl } from '../utils/getRedirectUrl';
import {
  removeOidcUserFromLocalStorage,
  saveCurrentPathForRedirect,
} from '../utils/localstorage';
import { getOidcCookie, startSessionValidation } from '../utils/oidcSession';

type Props = {
  children: ReactNode;
};

function AuthValidator({ children }: Props) {
  const { isAuthenticated, isLoading, signinRedirect, signoutRedirect, user } =
    useAuth();

  const [isCookieValid, setIsCookieValid] = useState(false);
  const router = useRouter();

  const searchParams = useSearchParams();
  const code = searchParams.get('code');

  useEffect(() => {
    if (!window?.location.toString().startsWith('http://localhost')) {
      (async () => {
        const res = await fetch(`${OIDC_AUTHORITY_URL}/api/v1/login/state`, {
          credentials: 'include',
        });
        const respone = await res.json();

        if (respone?.state === 'COMPLETE') {
          setIsCookieValid(true);
        } else {
          setIsCookieValid(false);
        }
      })();
    }
  }, [router]);

  useEffect(() => {
    const oidcCookie = getOidcCookie();
    if (!oidcCookie) {
      return;
    }

    if (!isCookieValid) {
      const intervalId = startSessionValidation(signoutRedirect);
      return () => {
        clearInterval(intervalId);
      };
    }

    if (isLoading) {
      return;
    }

    if (!isAuthenticated) {
      saveCurrentPathForRedirect();
      signinRedirect();
      return;
    }

    if (code) {
      const redirectUrl = getRedirectUrl();

      if (redirectUrl) {
        router.push(redirectUrl);
      }
      return;
    }

    if (user && oidcCookie !== user.profile.sub) {
      removeOidcUserFromLocalStorage();
      signinRedirect();
      return;
    }

    const intervalId = startSessionValidation(signoutRedirect);

    return () => {
      clearInterval(intervalId);
    };
  }, [
    code,
    isAuthenticated,
    isCookieValid,
    isLoading,
    router,
    signinRedirect,
    signoutRedirect,
    user,
  ]);

  return <>{children}</>;
}

export function OidcProvider({ children }: Props) {
  return (
    <AuthProvider {...OIDC_CONFIG}>
      <AuthValidator>{children}</AuthValidator>
    </AuthProvider>
  );
}
