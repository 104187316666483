import { useGtmDataLayer } from 'src/hooks/useGtmDataLayer';
import {
  ClickAnalyticsType,
  ContactInteractionAnalytics,
  NestedObject,
} from 'src/types';
import { detectExternalLink } from 'src/utils/detectExternalLink';
import { sendYMData } from 'src/utils/sendYMData';

interface FillFormFieldPopup {
  formType: 'popup';
  popupPlace: 'header' | 'body' | 'footer';
  buttonId: string;
}

export type AnalyticsFormType =
  | 'sale'
  | 'consultation'
  | 'consultation-lk'
  | 'calculator'
  | 'promo'
  | 'webinar'
  | 'hr'
  | 'partners'
  | 'marketplace'
  | 'subscription'
  | 'education'
  | 'other';

export interface TryFormProps {
  formType: AnalyticsFormType;
  action: 'Отправлено' | 'Ошибка' | 'Поля';
  errorCode?: string;
  fields?: string[];
}

export type searchAnalyticsType =
  | 'header'
  | 'serp'
  | 'live-menu-products'
  | 'live-page-products';

type SearchClicksAnalyticsType = {
  link: string;
  searchPhrase: string;
  type: 'menu-products' | 'page-all-products' | 'Search-SERP';
  source?: SearchClickAnalyticsSource;
};

export type SearchClickAnalyticsSource = 'all' | 'filter' | 'e-search';
interface FillFormFieldBody {
  formType: 'body';
  popupPlace?: undefined;
  buttonId?: undefined;
}

export type FillFormFieldBase = { fieldName: string } & (
  | FillFormFieldBody
  | FillFormFieldPopup
);

enum FormNames {
  platfroms = 'lead-platforms',
  products = 'lead-products',
  solutions = 'lead-solutions',
  services = 'lead-services',
  analogi = 'lead-analogi',
  education = 'lead-education',
  events = 'lead-events',
  offers = 'lead-offers',
  registration = 'webinar-registration',
  webinarMaterials = 'webinar-materials',
  digest = 'digest',
  digestBlog = 'digest-blog',
  digestNews = 'digest-news',
  searchRepost = 'search-report',
  consultation = 'consultation',
  withdrawal = 'other-withdrawal',
  feedback = 'other-feedback',
  china = 'other-china',
  wr = 'other-wr',
  cl = 'other-cl',
  cmsb = 'other-cmsb',
  mkp = 'lead-mkp',
  calculator = 'calculator',
  research = 'research',
}

export type SuccessfullSubmissionFormAnalyticsType = {
  zoneclick?: 'B' | 'P'; // body / popup
  formname: FormNames | `${FormNames}`;
};

export const checkGetParamsFromUrl = (url: string) => {
  if (
    url.indexOf('autotest') !== -1 ||
    url.indexOf('shadow') !== -1 ||
    url.indexOf('cloudtest') !== -1
  ) {
    return url;
  }

  return url.split('?')[0];
};

const checkSpace = () => {
  const url = window.location.href;
  const condition =
    url.indexOf('autotest') !== -1 ||
    url.indexOf('shadow') !== -1 ||
    url.indexOf('cloudtest') !== -1;

  if (
    condition ||
    process.env.NEXT_PUBLIC_SPACE === 'develop' ||
    process.env.NEXT_PUBLIC_SPACE === 'stage'
  ) {
    return 'dev';
  }

  return 'prod';
};

export const useAnalytics = () => {
  const { sendDataToDataLayer } = useGtmDataLayer();

  const sendAnalytics = (
    dataLayer: { [key: string]: string } | null,
    params: NestedObject<string>,
    reachGoal: string,
  ) => {
    if (dataLayer) {
      const dataLayerToSend = {
        ...dataLayer,
      };

      if (checkSpace() !== 'dev') {
        sendDataToDataLayer({
          dataLayer: dataLayerToSend,
        });
      }
    }
    sendYMData(reachGoal, params, checkSpace() === 'dev');
  };

  const fillFormField = ({
    formType,
    fieldName,
    popupPlace,
    buttonId,
  }: FillFormFieldBase) => {
    const dataLayerToSend: { [key: string]: string } = {
      event: 'test_event',
      event_category: 'forms',
      event_action: 'formFill',
      event_label: 'Form-fields-fill',
      retain_url: checkGetParamsFromUrl(window.location.href),
      form_type: formType,
      field_name: fieldName,
    };

    let ymData: NestedObject<string> = {
      'Form-fields-fill': {
        [checkGetParamsFromUrl(window.location.href)]: {
          body: fieldName,
        },
      },
    };

    if (formType === 'popup') {
      dataLayerToSend.popup_place = popupPlace;
      dataLayerToSend.popup_button_id = buttonId;

      ymData = {
        'Form-fields-fill': {
          [checkGetParamsFromUrl(window.location.href)]: {
            popup: {
              [popupPlace]: {
                [fieldName]: buttonId,
              },
            },
          },
        },
      };
    }

    sendAnalytics(dataLayerToSend, ymData, 'Form-fields-fill');
  };

  const serpClickAnalytics = (
    clickZone: 'site' | 'documentation',
    query: string,
    url: string,
  ) => {
    const analyticsType = 'Serp';

    const dataLayerToSend: { [key: string]: string } = {
      event: 'test_event',
      event_action: 'click',
      event_label: analyticsType,
      retain_url: checkGetParamsFromUrl(window.location.href),
      click_zone: clickZone,
      serp_query: query,
      serp_url: url,
    };

    const ymData = {
      [analyticsType]: {
        [clickZone]: {
          [query]: url,
        },
      },
    };

    sendAnalytics(dataLayerToSend, ymData, analyticsType);
  };

  const searchAnalytics = (
    type: searchAnalyticsType,
    value: string,
    query: string = '',
  ) => {
    const analyticsType = 'Search';

    const dataLayerToSend: { [key: string]: string } = {
      event: 'test_event',
      event_action: analyticsType,
      event_label: analyticsType,
      retain_url: checkGetParamsFromUrl(window.location.href),
      search_value: value,
      search_type: type,
      search_query: query,
    };

    const ymData = {
      [analyticsType]: {
        [checkGetParamsFromUrl(window.location.href)]: {
          [value]: {
            [type]: query,
          },
        },
      },
    };

    sendAnalytics(dataLayerToSend, ymData, analyticsType);
  };

  const clickAnalytics = ({
    action,
    clickZone,
    clickElement,
    clickContent,
    uniqueId,
    transitionType,
  }: ClickAnalyticsType) => {
    if (action.startsWith('/')) {
      action = `${window.location.origin}${action}`;
    }
    const dataLayerToSend: { [key: string]: string } = {
      event: 'test_event',
      event_category: 'forms',
      event_action: 'clicks',
      event_label: 'All-clicks',
      retain_url: checkGetParamsFromUrl(window.location.href),
      click_action: action,
      click_zone: clickZone,
      click_element: clickElement,
      click_content: clickContent,
      unique_id: uniqueId,
      transition_type: transitionType,
    };

    const ymData = {
      'All-clicks': {
        [checkGetParamsFromUrl(window.location.href)]: {
          [action]: {
            [clickZone]: {
              [clickElement]: {
                [clickContent]: {
                  [uniqueId]: transitionType,
                },
              },
            },
          },
        },
      },
    };

    sendAnalytics(dataLayerToSend, ymData, 'All-clicks');
  };

  const pageViewCustom = () => {
    const ymData = {
      'Page-V-custom': {
        [checkGetParamsFromUrl(window.location.href)]: window.location.href,
      },
    };

    sendAnalytics(null, ymData, 'Page-V-custom');
  };

  const tryFormAnalytics = ({
    formType,
    action,
    errorCode,
    fields,
  }: TryFormProps) => {
    const fieldsString = fields?.join(', ');

    const dataLayerToSend: { [key: string]: string } = {
      event: 'test_event',
      event_category: 'forms',
      event_action: 'tryForm',
      event_label: 'Try-form',
      retain_url: checkGetParamsFromUrl(window.location.href),
      form_type: formType,
      action: action,
    };

    if (action === 'Ошибка') {
      dataLayerToSend.error_code = errorCode!;
    }

    if (action === 'Поля') {
      dataLayerToSend.fields = fieldsString!;
    }

    const ymActionData =
      action === 'Ошибка'
        ? errorCode!
        : action === 'Поля'
        ? fieldsString!
        : 'Успешно отправлено';

    const ymData: NestedObject<string> = {
      'Try-form': {
        [checkGetParamsFromUrl(window.location.href)]: {
          [formType]: {
            [action]: ymActionData,
          },
        },
      },
    };

    sendAnalytics(dataLayerToSend, ymData, 'Try-form');
  };

  const funnelFormInput = () => {
    const dataLayerToSend: { [key: string]: string } = {
      event: 'test_event',
      event_category: 'forms',
      event_action: 'funnelFormInput',
      event_label: 'Funnel-form-input',
      retain_url: checkGetParamsFromUrl(window.location.href),
    };

    const inputYmData = {
      'Funnel-form-input': checkGetParamsFromUrl(window.location.href),
    };

    sendAnalytics(dataLayerToSend, inputYmData, 'Funnel-form-input');
  };

  const consultationFormSent = (
    calledFrom: 'body' | 'header',
    isAdAgree: boolean,
  ) => {
    const consultationDataLayer = {
      event: 'test_event',
      event_category: 'forms',
      event_action: 'formSuccess',
      event_label: 'Сonsultation-form-sent',
      retain_url: checkGetParamsFromUrl(window.location.href),
      called_from: calledFrom,
      adAgree: isAdAgree ? 'agree-ad' : 'disagree-ad',
    };
    const consultationYmData = {
      'Сonsultation-form-sent': {
        [checkGetParamsFromUrl(window.location.href)]: {
          calledFrom: isAdAgree ? 'agree-ad' : 'disagree-ad',
        },
      },
    };
    sendAnalytics(
      consultationDataLayer,
      consultationYmData,
      'Consultation-form-sent',
    );
  };

  const saleFormSent = (
    formType: 'sale' | 'consultation' | 'calculator' | 'promo',
    isAdAgree: boolean,
    product_type:
      | 'Advanced'
      | 'Облако VMware'
      | 'ML Space'
      | 'Evolution'
      | 'combo'
      | 'none',
  ) => {
    const agreement = isAdAgree ? 'agree-ad' : 'disagree-ad';

    const dataLayer = {
      event: 'test_event',
      event_category: 'forms',
      event_action: 'formSuccess',
      event_label: 'Sale-form-sent',
      retain_url: checkGetParamsFromUrl(window.location.href),
      adAgree: agreement,
      form_type: formType,
      product_type: product_type,
    };

    const ymData = {
      'Sale-form-sent': {
        [formType]: {
          [product_type]: agreement,
        },
      },
    };

    sendAnalytics(dataLayer, ymData, 'Sale-form-sent');
  };

  const allFormSent = (
    formType: AnalyticsFormType,
    isAdAgree: boolean,
    isPopup = false,
  ) => {
    const agreement = isAdAgree ? 'agree-ad' : 'disagree-ad';
    const formSentFrom = isPopup ? 'popup' : 'body';
    const formsDataLayer = {
      event: 'test_event',
      event_category: 'forms',
      event_action: 'formSuccess',
      event_label: 'All-form-sent',
      retain_url: checkGetParamsFromUrl(window.location.href),
      form_type: 'consultation',
      adAgree: agreement,
    };
    const formsYmData = {
      'All-form-sent': {
        [checkGetParamsFromUrl(window.location.href)]: {
          [formType]: {
            [agreement]: formSentFrom,
          },
        },
      },
    };

    sendAnalytics(formsDataLayer, formsYmData, 'All-form-sent');
  };

  const funnelFormSubmit = () => {
    const dataLayer = {
      event: 'test_event',
      event_category: 'consultation LeadForm', //FIXME: no hardcode
      event_action: 'formSubmit',
      event_label: 'Funnel-form-submit',
      retain_url: checkGetParamsFromUrl(window.location.href),
    };

    const ymData = {
      'Funnel-form-submit': checkGetParamsFromUrl(window.location.href),
    };

    sendAnalytics(dataLayer, ymData, 'Funnel-form-submit');
  };

  const digestFormAnalytics = () => {
    const dataLayerToSend: { [key: string]: string } = {
      event: 'test_event',
      event_category: 'digest_lead',
      event_action: 'formSuccess',
      event_label: 'Digest-form',
    };

    const ymData = {
      'Digest-form': checkGetParamsFromUrl(window.location.href),
    };

    sendAnalytics(dataLayerToSend, ymData, 'Digest-form');
  };

  const webinarFormAnalytics = (subscription: boolean) => {
    const sub = subscription ? 'agree-ad' : 'disagree-ad';
    const dataLayerToSend: { [key: string]: string } = {
      event: 'test_event',
      event_category: 'webinar_lead',
      event_action: 'formSuccess',
      event_label: 'Webinar-form',
      retain_url: checkGetParamsFromUrl(window.location.href),
      subscription: sub,
    };

    const ymData = {
      'Webinar-form': {
        [checkGetParamsFromUrl(window.location.href)]: sub,
      },
    };

    sendAnalytics(dataLayerToSend, ymData, 'Webinar-form');
  };

  const videoPlayAnalytics = (videoSrc: string) => {
    const dataLayerToSend: { [key: string]: string } = {
      event: 'test_event',
      event_category: 'forms',
      event_action: 'Video-play',
      event_label: 'Video-play',
      retain_url: checkGetParamsFromUrl(window.location.href),
      video_id: videoSrc,
    };

    const ymData = {
      'Video-play': {
        [checkGetParamsFromUrl(window.location.href)]: videoSrc,
      },
    };

    sendAnalytics(dataLayerToSend, ymData, 'Video-play');
  };

  const contactInteractionAnalytics = ({
    interactionType,
    contact,
    interactionText,
  }: ContactInteractionAnalytics) => {
    const dataLayerToSend: { [key: string]: string } = {
      event: 'test_event',
      event_category: 'forms',
      event_action: 'Email-interaction',
      event_label: 'Contact-interaction',
      retain_url: checkGetParamsFromUrl(window.location.href),
      interactionType: interactionType,
      contact: contact,
      interactionText: interactionText,
    };

    const ymData = {
      'Contact-interaction': {
        'Email-interaction': {
          [checkGetParamsFromUrl(window.location.href)]: {
            [interactionType]: {
              [contact]: interactionText,
            },
          },
        },
      },
    };

    sendAnalytics(dataLayerToSend, ymData, 'Contact-interaction');
  };

  const customAnalytics = (reachGoal: string, params: string[]) => {
    const dataLayerToSend: { [key: string]: string } = {};

    const generateNestedObject = (params: string[]) => {
      if (params.length < 2) {
        return {};
      }

      const lastParam = params.pop();
      return params.reverse().reduce((acc: any, param: string) => {
        return { [param]: acc };
      }, lastParam);
    };

    params.unshift(reachGoal);

    const ymData = generateNestedObject(params);

    sendAnalytics(dataLayerToSend, ymData, reachGoal);
  };

  const searchClickAnalytics = ({
    link,
    searchPhrase,
    type,
    source,
  }: SearchClicksAnalyticsType) => {
    const analyticsType = 'Search-click';

    const dataLayerToSend: { [key: string]: string } = {
      event: 'test_event',
      event_action: analyticsType,
      event_label: analyticsType,
      retain_url: checkGetParamsFromUrl(window.location.href),
      search_value: searchPhrase,
      search_type: type,
    };

    const currentLink = detectExternalLink(link)
      ? link
      : `${window.location.host}${link}`;

    const ymData = {
      [analyticsType]: {
        [checkGetParamsFromUrl(window.location.href)]: {
          [currentLink]: {
            [searchPhrase]: {
              [type]: source || '',
            },
          },
        },
      },
    };

    sendAnalytics(dataLayerToSend, ymData, analyticsType);
  };

  // Event №10
  const successfullSubmissionFormAnalytics = ({
    zoneclick,
    formname,
  }: SuccessfullSubmissionFormAnalyticsType) => {
    const pathname =
      checkGetParamsFromUrl(window.location.pathname)
        .replace('/', '')
        .replaceAll('/', '-') || 'index';

    const title = `FS_${zoneclick}_${formname}_${pathname}`;

    const dataLayerToSend: { [key: string]: string } = {
      event: title,
    };

    const ymData = { [title]: '' };

    sendAnalytics(dataLayerToSend, ymData, title);
  };

  return {
    saleFormSent,
    allFormSent,
    consultationFormSent,
    funnelFormSubmit,
    funnelFormInput,
    sendAnalytics,
    fillFormField,
    searchAnalytics,
    searchClickAnalytics,
    clickAnalytics,
    tryFormAnalytics,
    digestFormAnalytics,
    webinarFormAnalytics,
    contactInteractionAnalytics,
    videoPlayAnalytics,
    serpClickAnalytics,
    customAnalytics,
    pageViewCustom,
    successfullSubmissionFormAnalytics,
  };
};
