import { UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';
import {
  OIDC_AUTHORITY_URL,
  OIDC_CLIENT_ID,
  OIDC_REDIRECT_URI,
} from 'src/global';

export const OIDC_CONFIG: UserManagerSettings = {
  authority: `${OIDC_AUTHORITY_URL}/auth/system/openid/`,
  client_id: OIDC_CLIENT_ID,
  redirect_uri: OIDC_REDIRECT_URI,
  response_type: 'code',
  scope: 'openid',
  post_logout_redirect_uri: OIDC_REDIRECT_URI,
  userStore:
    typeof window === 'undefined'
      ? undefined
      : new WebStorageStateStore({ store: window.localStorage }),
  automaticSilentRenew: true,
};
