import { PropsWithChildren, useMemo, useState } from 'react';

import { EMPTY_SEARCH_PARAMS } from './constants';
import { SearchParamsContext } from './SearchParamsContext';

export function SearchParamsProvider(props: PropsWithChildren) {
  const { children } = props;

  const [searchParams, setSearchParams] = useState(EMPTY_SEARCH_PARAMS);

  const value = useMemo(
    () => ({
      searchParams,
      setSearchParams,
    }),
    [searchParams],
  );

  return (
    <SearchParamsContext.Provider value={value}>
      {children}
    </SearchParamsContext.Provider>
  );
}
