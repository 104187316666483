export const backendHost = process.env.BACKEND_URL || 'https://api.cloud.ru';
export const consoleUrl =
  process.env.NEXT_PUBLIC_CONSOLE_URL || 'https://console.cloud.ru';
export const consoleMarketplaceUrl =
  process.env.NEXT_PUBLIC_MARKETPLACE_CONSOLE_URL ||
  'https://marketplace.cloud.ru';
export const consoleUrlReg = `${consoleUrl}/registration/`;
export const consoleRedirectUrl = `${consoleUrl}/static-page/login-destination`;

export const gtmId = process.env.NEXT_PUBLIC_GTM_ID || 'GTM-KLNL8NV';
export const ymId = process.env.NEXT_PUBLIC_YM_ID || '75066511';
export const testYmId = '93605518';
export const revalidate = 120; // как часто обращаться к бэку в статичных страницах - в секундах

export const OIDC_AUTHORITY_URL =
  process.env.NEXT_PUBLIC_OIDC_AUTHORITY_URL ?? '';
export const OIDC_CLIENT_ID = process.env.NEXT_PUBLIC_OIDC_CLIENT_ID ?? '';
export const OIDC_REDIRECT_URI =
  process.env.NEXT_PUBLIC_OIDC_REDIRECT_URI ?? '';
export const INITIAL_URL_LOCALSTORAGE_KEY = 'oidc-site-redirect-url';
export const COOKIE_DOMAIN = process.env.NEXT_PUBLIC_OIDC_DOMAIN ?? 'cloud.ru';
