import { useSearchParams } from 'next/navigation';
import { Suspense, useContext, useEffect } from 'react';

import { SearchParamsContext } from './SearchParamsContext';

function SearchParamsReader() {
  const clientSearchParams = useSearchParams();

  const { setSearchParams } = useContext(SearchParamsContext);

  useEffect(() => {
    if (clientSearchParams) {
      setSearchParams(clientSearchParams);
    }
  }, [clientSearchParams, setSearchParams]);

  return null;
}

export function SearchParamsInitializer() {
  return (
    <Suspense>
      <SearchParamsReader />
    </Suspense>
  );
}
