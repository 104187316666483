import { usePathname } from 'next/navigation';
import { useEffect, useRef } from 'react';

export const useRouteChangeEvent = (handler: () => void) => {
  const pathname = usePathname();
  const prevPathname = useRef(pathname);

  useEffect(() => {
    if (pathname !== prevPathname.current) {
      handler();
      prevPathname.current = pathname;
    }
  }, [handler, pathname]);
};
